import { faUserAlt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Avatar from '@mui/material/Avatar';

import { NextImage } from '../image';

import { UserIconWrapper } from './style';

type AvatarPropsType = {
  src?: string;
  alt: string;
  errorImage: string;
  sx?: Object;
  className?: string;
  variant?: 'circular' | 'rounded' | 'square';
};

const MyAvatar = ({ src, alt, errorImage, sx, className, variant = 'circular' }: AvatarPropsType) => {
  if (src) {
    return (
      <Avatar alt={alt} sx={sx} variant={variant} className={className}>
        <NextImage src={src} alt={alt} layout="fill" errorImage={errorImage} />
      </Avatar>
    );
  }

  return (
    <UserIconWrapper sx={sx} display="flex" justifyContent="center" alignItems="center">
      <FontAwesomeIcon icon={faUserAlt} />
    </UserIconWrapper>
  );
};

export default MyAvatar;
