import { useEffect } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import { useTranslation } from 'next-i18next';

import { Props } from './card';

import enrollmentAPI, { ENROLLMENT_END_POINT } from 'src/api/enrollment';
import { ACADEMIC_TYPE, COURSE_ITEMS_CONTENT_TYPE } from 'src/helpers/constants';
import { useRequest, useProfile } from 'src/helpers/use';
import { isExpired, numberWithCommas } from 'src/helpers/utils';
import { IEnrollment } from 'src/interfaces';

const buddhistEra = require('dayjs/plugin/buddhistEra');

dayjs.extend(buddhistEra);

type CardFooterProps = {
  matches: boolean;
  setCanLearn: (canLearn: boolean) => void;
  isVertical?: boolean;
} & Props;

const CardAcademicFooter = ({ course, matches, setCanLearn, isVertical }: CardFooterProps) => {
  const { t } = useTranslation('common');
  const { profile, isLoading } = useProfile();
  const { isLoading: enrollmentsLoading, isValidating } = useRequest<IEnrollment[]>(
    profile ? ENROLLMENT_END_POINT.ENROLLMENTS_ME : null,
    enrollmentAPI.fetchCoursesEnrollment,
    { revalidateIfStale: false },
  );

  const { enrollment } = course;
  const evaluation_results = enrollment?.evaluation_results;

  const academicDoneAllAssessment = () => {
    const quizzes = course.course_items.filter((item) => item.content_type === COURSE_ITEMS_CONTENT_TYPE.QUIZ);
    const totalQuizzes = quizzes.length;
    return (
      totalQuizzes <=
        (enrollment?.finished_logs?.filter(
          (log) => quizzes.find((item) => item.id === log.course_item_id) && log.finished,
        ).length || 0) && totalQuizzes > 0
    );
  };

  const academicGraded = () => {
    if (evaluation_results) {
      return evaluation_results.status === 'graded';
    }
    return false;
  };

  const academicExpiredForExam = () => enrollment?.exam_expiry_time && isExpired(enrollment.exam_expiry_time);

  const academicPassed = () => {
    if (evaluation_results && evaluation_results.status === 'graded') {
      return evaluation_results.is_pass;
    }
    return false;
  };

  useEffect(() => {
    if (course.enrollment) setCanLearn(true);
  }, [course.enrollment]);

  const renderFooter = () => {
    let courseText = (
      <Typography sx={{ color: 'primary.main' }} variant="h5_deprecated" component="span">
        {t('home.course_card_continue_learning')}
      </Typography>
    );
    let coursePriceAudit = null;
    let coursePriceCredit = null;
    let title = '';
    const textBaht = t('home.course_card_baht');
    const findComingSoon = course.degree_courses?.every((val) => val.coming_soon);
    if (!course.degree_courses.length || findComingSoon) {
      courseText = (
        <Typography color="text.disabled" variant="h5_deprecated" component="span">
          {t('home.course_card_coming_soon')}
        </Typography>
      );
    } else if (!enrollment) {
      const auditOriginalPrice =
        course.product?.product_skus?.find((x) => x.product_sale_type === ACADEMIC_TYPE.AUDIT)
          ?.product_sku_distributions[0]?.original_price || 0;
      const auditSalePrice =
        course.product?.product_skus?.find((x) => x.product_sale_type === ACADEMIC_TYPE.AUDIT)
          ?.product_sku_distributions[0]?.sale_price || 0;
      const creditOriginalPrice =
        course.product?.product_skus?.find((x) => x.product_sale_type === ACADEMIC_TYPE.CREDIT)
          ?.product_sku_distributions[0]?.original_price || 0;
      const creditSalePrice =
        course.product?.product_skus?.find((x) => x.product_sale_type === ACADEMIC_TYPE.CREDIT)
          ?.product_sku_distributions[0]?.sale_price || 0;
      coursePriceAudit = (
        <Box className="box-price-academic">
          {auditOriginalPrice ? (
            <Grid container direction="row" justifyContent="flex-end" alignItems="center">
              <Grid item xs={isVertical ? 6 : 5} lg={6}>
                <Typography
                  className="price-academic"
                  color={auditOriginalPrice <= auditSalePrice ? 'primary.main' : 'error.dark'}
                  variant="h5_deprecated"
                  component="span"
                >
                  {numberWithCommas(auditSalePrice)} {textBaht}
                </Typography>
              </Grid>

              <Grid
                item
                xs={isVertical ? 6 : 4}
                md={6}
                lg={6}
                sx={matches || isVertical ? { textAlign: 'center' } : null}
              >
                {auditOriginalPrice > auditSalePrice && (
                  <Typography
                    color="text.disabled"
                    className="strike-center discount"
                    variant="h5_deprecated"
                    component="span"
                  >
                    {numberWithCommas(auditOriginalPrice)} {textBaht}
                  </Typography>
                )}
              </Grid>
            </Grid>
          ) : matches || isVertical ? (
            <Typography sx={{ flexGrow: 1 }} color="text.secondary" variant="h5_deprecated" component="span">
              {numberWithCommas(auditSalePrice)} {textBaht}
            </Typography>
          ) : (
            <Grid container direction="row" justifyContent="flex-end" alignItems="center">
              <Grid item xs={6}>
                <Typography sx={{ flexGrow: 1 }} color="text.secondary" variant="h5_deprecated" component="span">
                  {numberWithCommas(auditSalePrice)} {textBaht}
                </Typography>
              </Grid>
            </Grid>
          )}

          <Typography color="text.secondary" className="academic discount" variant="h5_deprecated" component="span">
            {t('home.course_card_audit_learning')}
          </Typography>
        </Box>
      );
      coursePriceCredit = (
        <Box className="box-price-academic">
          {creditOriginalPrice ? (
            <Grid container direction="row" justifyContent="flex-end" alignItems="center">
              <Grid item xs={isVertical ? 6 : 5} lg={6}>
                <Typography
                  className="price-academic"
                  color={creditOriginalPrice <= creditSalePrice ? 'primary.main' : 'error.dark'}
                  variant="h5_deprecated"
                  component="span"
                >
                  {numberWithCommas(creditSalePrice)} {textBaht}
                </Typography>
              </Grid>
              <Grid
                item
                xs={isVertical ? 6 : 4}
                md={6}
                lg={6}
                sx={matches || isVertical ? { textAlign: 'center' } : null}
              >
                {creditOriginalPrice > creditSalePrice && (
                  <Typography
                    color="text.disabled"
                    className="strike-center discount"
                    variant="h5_deprecated"
                    component="span"
                  >
                    {numberWithCommas(creditOriginalPrice)} {textBaht}
                  </Typography>
                )}
              </Grid>
            </Grid>
          ) : matches || isVertical ? (
            <Typography sx={{ flexGrow: 1 }} color="primary.main" variant="h5_deprecated" component="span">
              {numberWithCommas(creditSalePrice)} {textBaht}
            </Typography>
          ) : (
            <Grid container direction="row" justifyContent="flex-end" alignItems="center">
              <Grid item xs={6}>
                <Typography sx={{ flexGrow: 1 }} color="primary.main" variant="h5_deprecated" component="span">
                  {numberWithCommas(creditSalePrice)} {textBaht}
                </Typography>
              </Grid>
            </Grid>
          )}
          <Typography color="primary.main" className="academic discount" variant="h5_deprecated" component="span">
            {t('home.course_card_credit_learning')}
          </Typography>
        </Box>
      );
    } else if (enrollment.academic_type === ACADEMIC_TYPE.CREDIT && enrollment.status <= 1) {
      if (academicDoneAllAssessment() && !academicGraded()) {
        title = t('home.course_card_pending_for_issue_grade');
      } else if (academicExpiredForExam() && !academicGraded()) {
        title = t('home.course_card_exam_expired');
      } else if (academicGraded() && academicPassed()) {
        title = t('home.course_card_credit_pass');
      } else if (academicGraded() && !academicPassed()) {
        title = t('home.course_card_not_pass');
      } else {
        title = t('home.course_card_finish_exam_in', {
          date: dayjs(enrollment.exam_expiry_time).format('DD/MM/BBBB'),
        });
      }
    } else {
      title = t('home.course_card_no_expiry');
    }

    if (isLoading || (enrollmentsLoading && isValidating)) {
      return <Skeleton animation="wave" height={40} width="60%" />;
    }
    return (
      <Box className="price price-bottom-space">
        <Typography variant="body2" component="span">
          {title}
        </Typography>
        <Box>{coursePriceAudit || courseText}</Box>
        <Box>{coursePriceCredit}</Box>
      </Box>
    );
  };

  return renderFooter();
};

export default CardAcademicFooter;
